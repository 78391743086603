import { combineReducers } from 'redux';

import { IUserState, user } from './user';
import { clients } from './clients';

export interface IState {
  user: IUserState;
  clients: string[];
}

export const rootReducer = combineReducers({
  user,
  clients,
});
