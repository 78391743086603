import * as React from 'react';
import ReactCodeInput from 'react-code-input';
import { useSelector } from 'react-redux';
import { IState } from '../../store/reducers';
import { Api } from '../../utils/Api';
import { Button } from '../Button';

import './verify.scss';

interface IVerify {
  email: string;
  onValidCode: () => void;
  className?: string;
}

export const Verify: React.FC<IVerify> = ({ email, onValidCode }) => {
  const [verifyValues, setVerifyValues] = React.useState<string>();
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { token } = useSelector((state: IState) => state.user);

  const handleChange = (value: string) => {
    setVerifyValues(value);
    setIsCompleted(/[0-9]{6}$/.test(value));
  };

  const handleSubmit = async (event?: any) => {
    event?.preventDefault();
    setIsLoading(true);
    const code = verifyValues || '';
    await Api.verifyUser({
      token,
      code,
    })
      .then(({ data }) => {
        if (data.error) {
          setError(data.error);
        } else {
          onValidCode();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="verify">
      <h1>Verify email</h1>
      <h2>Enter the 6 digit code sent to {email}</h2>
      {error && <div className="statusMessage --error">{error}</div>}
      <div className="mt-3 mb-3 verify-container">
        <ReactCodeInput
          inputMode="numeric"
          name=""
          fields={6}
          onChange={handleChange}
        />
      </div>
      <Button
        label="Verify Email"
        disabled={!isCompleted}
        onClick={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};
