import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../Footer';
import { Header } from '../Header';

import './page.scss';

interface IPageProps {
  title?: string;
  includeFooter?: boolean;
  includeHeader?: boolean;
  includeBackButton?: boolean;
}

export const Page: React.FC<IPageProps> = ({
  title,
  includeFooter = false,
  includeHeader = false,
  includeBackButton = false,
  children,
}) => {
  return (
    <>
      <Helmet>
        <title>
          {title ? title + ' • Vital Intelligence' : 'Vital Intelligence'}
        </title>
      </Helmet>
      {includeHeader && <Header includeBackButton={includeBackButton} />}
      <div className="page">{children}</div>
      {includeFooter && <Footer />}
    </>
  );
};
