import * as React from 'react';
import { Logo as LogoImage } from '../../assets/images/Logo';

import './logo.scss';

type logoVariants = 'black' | 'white';
type sizeVariants = 'large' | 'small';

interface ILogo {
  variant?: logoVariants;
  size?: sizeVariants;
}

export const Logo: React.FC<ILogo> = ({
  variant = 'black',
  size = 'large',
}) => (
  <div className={`logo${' --' + variant + ' --' + size}`}>
    <div className={`logo-main-container${' --' + size}`}>
      <LogoImage />
    </div>
  </div>
);
