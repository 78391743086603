import "./App.scss";
import { Router } from "./Router";

import "apercu-font";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return <Router />;
};

export default App;
