import * as React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';

import { Logo } from '../Logo';

import './header.scss';

interface IHeader {
  includeBackButton?: boolean;
}

export const Header: React.FC<IHeader> = ({ includeBackButton }) => {
  const history = useHistory();

  return (
    <div className="header">
      {includeBackButton && (
        <ArrowLeftOutlined className="back" onClick={() => history.goBack()} />
      )}
      <Logo />
    </div>
  );
};
