import * as types from '../../store/types';

export interface IUserState {
  token: string;
  userId: string;
}

const initialUserState: IUserState = {
  token: '',
  userId: '',
};

export const user = (state = initialUserState, action: any) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return {
        ...state,
        token: action?.payload?.token,
        userId: action?.payload?.userId,
      };

    case types.LOAD_USER:
      return {
        ...state,
        userId: state.userId,
        ...action.payload,
      };

    case types.LOGOUT_USER:
      return initialUserState;

    default:
      return state;
  }
};
