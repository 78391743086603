import { UserOutlined } from '../../assets/images/UserOutlined';
import { UserCheckOutlined } from '../../assets/images/UserCheckOutlined';
import { VideoOutlined } from '../../assets/images/VideoOutlined';
import { ClockOutlined } from '../../assets/images/ClockOutlined';
import { UploadOutlined } from '../../assets/images/UploadOutlined';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';

import { useEffect, useState } from 'react';
import { Capture } from '../../components/Capture';
import { Loading } from '../../components/Loading';
import { Api } from '../../utils/Api';

import './dashboard.scss';
import { useSelector } from 'react-redux';
import { IState } from '../../store/reducers';
import { useHistory } from 'react-router';

export const DashboardScreen: React.FC = () => {
  const [isCaptureVisible, setIsCaptureVisible] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [video, setVideo] = useState<Blob>();

  const history = useHistory();

  const { token } = useSelector((state: IState) => state.user);

  useEffect(() => {
    if (video) {
      handleVideoUpload();
      setIsCaptureVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  const handleCaptureVideo = () => {
    setIsCaptureVisible(true);
  };

  const handleVideoUpload = async () => {
    setIsUploading(true);
    try {
      if (!video) {
        setIsUploading(false);
        return;
      }

      const { data } = await Api.processVideo({ token, video });

      history.push({ pathname: `/results`, state: [data] });
    } catch (error: any) {
      new Error(error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Page includeHeader title="Dashboard">
      {isUploading ? (
        <div className="uploading">
          <Loading message="Uploading Video..." />
        </div>
      ) : !isCaptureVisible ? (
        <>
          <div className="dashboard">
            {video && (
              <div className="dashboard-videoContainer">
                {video && (
                  <video
                    src={URL.createObjectURL(video)}
                    autoPlay
                    playsInline
                    muted
                  />
                )}
              </div>
            )}
            <div className="dashboard-instructions">
              <div className="dashboard-instructions-item">
                <UserOutlined stroke="#07bd80" />
                Please ensure your full face is in view, and close to the
                camera.
              </div>
              <hr />
              <div className="dashboard-instructions-item">
                <UserCheckOutlined stroke="#07bd80" />
                Please remain still while recording.
              </div>
              <hr />
              <div className="dashboard-instructions-item">
                <VideoOutlined stroke="#07bd80" />
                Press 'Capture Video' when you are ready to begin capturing
                video. You must allow access to your device's camera.
              </div>
              <hr />
              <div className="dashboard-instructions-item">
                <ClockOutlined stroke="#07bd80" />
                The recording will end automatically after 20 seconds of data
                has been collected.
              </div>
              <hr />
              <div className="dashboard-instructions-item">
                <UploadOutlined stroke="#07bd80" />
                Press 'Upload Video' when you are happy to submit your video.
                You can review your video before submitting, and choose to
                re-record if necessary.
              </div>
            </div>
            <div className="buttonGroup">
              {video && (
                <Button
                  label="upload the video"
                  icon={<UploadOutlined stroke="white" />}
                  onClick={handleVideoUpload}
                />
              )}
              <Button
                label={!video ? 'capture video' : 'capture again'}
                icon={<VideoOutlined stroke={!video ? '#07bd80' : 'white'} />}
                variant={!video ? 'primary' : 'outline-secondary'}
                style={video ? { background: 'white' } : null}
                onClick={handleCaptureVideo}
              />
            </div>
          </div>
        </>
      ) : (
        <Capture setVideo={setVideo} />
      )}
    </Page>
  );
};
