import React, { useEffect, useRef } from 'react';
import RecordRTC from 'recordrtc';

import './capture.scss';

interface ICapture {
  setVideo: (videoUrl: any) => void;
}

// Need to wait at least 15s for vitals to be recorded
const TIME_TO_WAIT = 15000;

// Wait 3s for user to prep before auto-start
const PREP_TIME = 4000;

export const Capture: React.FC<ICapture> = (props) => {
  const videoRef: any = useRef();
  const [isCapturing, setIsCapturing] = React.useState(false);
  const [isCountdownTimerVisible, setIsCountdownTimerVisible] =
    React.useState(false);
  const [recordingTimer, setRecordingTimer] = React.useState(TIME_TO_WAIT);
  const [countdownTimer, setCountdownTimer] = React.useState(PREP_TIME);
  const [stream, setStream] = React.useState<any>(null);

  useEffect(() => {
    if (!stream && !isCapturing) {
      initStream();
      initTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stream && !isCountdownTimerVisible) {
      handleCapture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream, isCountdownTimerVisible]);

  useEffect(() => {
    let interval: any = null;

    if (isCapturing) {
      interval = setInterval(() => {
        setRecordingTimer((prevTime) => {
          const currentTime = prevTime - 10;
          if (currentTime === 0) {
            return -1;
          }
          return currentTime;
        });
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isCapturing]);

  const handleDataAvailable = async (blob: any, recorder: RecordRTC) => {
    if (blob.size > 0) {
      // Has the video been stopped? If so, record data!
      if (!isCapturing) {
        props.setVideo(handleVideoProcessing([blob]));
        stream?.getTracks().forEach((track: any) => track.stop());
        recorder.destroy();
      }
    }
  };

  const initTimer = () => {
    // Set countdown timer to visible
    setIsCountdownTimerVisible(true);

    // Start counting down from specified prep time
    setInterval(() => {
      // Set the current time so we can view it
      setCountdownTimer((prevTime) => {
        const currentTime = prevTime - 10;
        if (currentTime === 0) {
          // Close the countdown timer
          setIsCountdownTimerVisible(false);
          return -1;
        }
        return currentTime;
      });
    }, 10);
  };

  const initStream = async (event?: any) => {
    const video: any = videoRef.current;

    navigator.mediaDevices
      .getUserMedia({
        video: { width: { min: 320, max: 640 } },
        audio: false,
      })
      .then(async function (stream) {
        video.srcObject = stream;
        setStream(stream);
      });
  };

  const handleCapture = async () => {
    if (!isCapturing) {
      const recorder = new RecordRTC(stream, {
        type: 'video',
        // Required in order for vitals to be processed properly and for it to work on mac/ios
        frameRate: 30,
      });

      recorder.startRecording();
      setIsCapturing(true);

      const sleep = (m: any) => new Promise((r) => setTimeout(r, m));
      await sleep(TIME_TO_WAIT);

      recorder.stopRecording(function () {
        let blob = recorder.getBlob();
        handleDataAvailable(blob, recorder);
      });
      setIsCapturing(false);
    }
  };

  const handleVideoProcessing = (data: any) => {
    if (data.length) {
      const blob = new Blob(data, {
        type: 'video/mp4',
      });
      setRecordingTimer(TIME_TO_WAIT);

      return blob;
    }
  };

  return (
    <div className="capture">
      <h3 className="capture-time">
        <span className="capture-time-dot" />
        <span>
          {('0' + Math.floor((recordingTimer / 60000) % 60)).slice(-2)}:
        </span>
        <span>
          {('0' + Math.floor((recordingTimer / 1000) % 60)).slice(-2)}
        </span>
      </h3>
      <video
        autoPlay
        playsInline
        muted
        className="capture-canvas"
        ref={videoRef}
      />
      {isCountdownTimerVisible && (
        <div className="capture-countdown">
          {Math.floor((countdownTimer / 1000) % 60)}
        </div>
      )}
    </div>
  );
};
