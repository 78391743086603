// Language
export const SET_LANGUAGE = 'SET_LANGUAGE';

// User
export const LOAD_USER = 'LOAD_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

// Clients
export const LOAD_CLIENTS = 'LOAD_CLIENTS';
