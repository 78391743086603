import { Dispatch } from 'redux';

import * as types from '../types';

export const loadClients = (data: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.LOAD_CLIENTS,
    payload: data,
  });
};
