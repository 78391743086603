import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { Api } from '../../utils/Api';

import './resetPassword.scss';

export const ResetPasswordScreen: React.FC = () => {
  const [values, setValues] = useState({
    newPassword: '',
  });
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token: string | null = params.get('token') || '';
    setToken(token);
  }, []);

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+{}|[\]:\\";'<=\-+>?,./`~]{6,}$/,
        'Your password needs to be at least 6 characters long',
      )
      .required(),
  });

  const handleSubmit = async (values: any) => {
    setValues(values);

    const { data } = await Api.resetPassword({
      password: values.newPassword,
      token,
    });
    if (data.error) {
      setError(data.error);
    } else {
      setIsPasswordChanged(true);
    }
  };

  return (
    <Page includeHeader includeBackButton title="Reset password">
      <div className="resetPassword">
        {isPasswordChanged ? (
          <div className="statusMessage --success">
            Your password was reset!
          </div>
        ) : (
          <>
            <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={values}
              validateOnBlur
              validateOnChange={false}
            >
              {({
                handleSubmit,
                setFieldValue,
                validateField,
                values,
                errors,
              }) => {
                const isSubmitDisabled =
                  !!errors.newPassword || !values.newPassword;
                return (
                  <Form onSubmit={handleSubmit}>
                    <h1>Reset your password</h1>
                    {error && (
                      <div className="statusMessage --error">{error}</div>
                    )}
                    <InputGroup className="mt-4 form">
                      <InputGroup.Text className="form-item --text">
                        Password
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Enter a new password"
                        className="form-item --input"
                        type="password"
                        value={values.newPassword}
                        name="newPassword"
                        isInvalid={!!errors.newPassword}
                        onChange={(e) =>
                          setFieldValue('newPassword', e.target.value)
                        }
                        onBlur={() => validateField('newPassword')}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.newPassword}
                      </FormControl.Feedback>
                    </InputGroup>
                    <Button
                      label="reset password"
                      type="submit"
                      disabled={isSubmitDisabled}
                      className="mt-4"
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        <Button label="back to login" variant="outline-primary" to="/login" />
      </div>
    </Page>
  );
};
