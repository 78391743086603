import { ISvg } from '../../lib/typings';

export const Drop2Outlined: React.FC<ISvg> = ({ stroke = 'currentColor' }) => (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    stroke={stroke}
    strokeWidth={0}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6372 18.1543C24.3546 21.9395 23.2565 24.5348 21.4654 26.2201C19.6095 27.9663 16.8542 28.875 14.111 28.875C11.3319 28.875 8.74629 28.0719 6.98829 26.41C5.27963 24.7947 4.14302 22.1699 4.60183 18.0406C5.05325 13.9778 7.57774 9.88082 10.1529 6.69251C11.4212 5.12223 12.6617 3.82104 13.5847 2.91314C13.7531 2.74753 13.9107 2.59525 14.0556 2.45706C14.1933 2.60191 14.3431 2.76115 14.5032 2.93388C15.3854 3.88541 16.5781 5.24059 17.818 6.85219C20.3331 10.1211 22.8999 14.2641 23.6372 18.1543Z"
      stroke={stroke}
      stroke-width="3"
    />
    <path
      d="M10.3299 23.0541H17.6701V21.1932H13.6124L16.433 17.6156C17.1258 16.7333 17.4227 15.8509 17.4227 14.9525C17.4227 13.0595 16.1691 11.6959 13.9753 11.6959C12.0454 11.6959 10.6268 12.9793 10.5113 14.6798L12.4247 15.0328C12.5567 14.0862 13.101 13.5568 13.9258 13.5568C14.866 13.5568 15.3443 14.1183 15.3443 15.0328C15.3443 15.6745 15.1464 16.2199 14.4536 17.0702L10.3299 22.252V23.0541Z"
      fill={stroke}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 23.375V22.1423L14.195 16.8709C14.8584 16.0567 15.0144 15.5796 15.0144 15.0328C15.0144 14.6262 14.9081 14.3445 14.7431 14.1672C14.5828 13.9951 14.3264 13.8777 13.9258 13.8777C13.5909 13.8777 13.3364 13.9827 13.1497 14.1658C12.9581 14.3537 12.8109 14.6506 12.7516 15.0759L12.7049 15.411L10.1629 14.9421L10.1822 14.6587C10.3099 12.7777 11.884 11.375 13.9753 11.375C15.1443 11.375 16.0962 11.74 16.7561 12.3886C17.4151 13.0362 17.7526 13.9367 17.7526 14.9525C17.7526 15.9272 17.4281 16.8769 16.6952 17.8104L14.2811 20.8723H18V23.375H10ZM13.6124 21.1932L16.433 17.6156C17.1258 16.7333 17.4227 15.8509 17.4227 14.9525C17.4227 13.0595 16.1691 11.6959 13.9753 11.6959C12.0454 11.6959 10.6268 12.9793 10.5113 14.6798L12.4247 15.0328C12.4389 14.9311 12.4579 14.8342 12.4814 14.7423C12.6769 13.9786 13.1896 13.5568 13.9258 13.5568C14.866 13.5568 15.3443 14.1183 15.3443 15.0328C15.3443 15.6745 15.1464 16.2199 14.4536 17.0702L10.3299 22.252V23.0541H17.6701V21.1932H13.6124Z"
      fill={stroke}
    />
  </svg>
);
