import { Dispatch } from 'redux';
import { TOKEN_IDENTIFIER } from '../../utils/Api';

import * as types from '../types';

export const loginUser = (data: any) => async (dispatch: Dispatch) => {
  localStorage.setItem(TOKEN_IDENTIFIER, data?.token);
  dispatch({
    type: types.LOGIN_USER,
    payload: data,
  });
};

export const loadUser = (data: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.LOAD_USER,
    payload: data,
  });
};

export const logoutUser = () => async (dispatch: Dispatch) => {
  localStorage.removeItem(TOKEN_IDENTIFIER);
  dispatch({
    type: types.LOGOUT_USER,
  });
};

export const loadClients = (data: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.LOAD_CLIENTS,
    payload: data,
  });
};
