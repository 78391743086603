import Axios from 'axios';
import {
  IForgotPasswordParams,
  IProcessVideoParams,
  IResetPasswordParams,
  IUserCredParams,
  IUserParams,
  IVerifyUserParams,
} from '../lib/typings';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const TOKEN_IDENTIFIER = 'com.vi.auth.token';
const token: string | null = localStorage.getItem(TOKEN_IDENTIFIER);
var csrftoken = cookies.get('csrftoken');

const v1 = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'X-CSRFToken': csrftoken,
  },
});

v1.interceptors.response.use(
  (config: any) => config,
  (error: any) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem(TOKEN_IDENTIFIER);
      window.location.reload();
      return;
    }

    return Promise.reject(error);
  },
);

class API {
  public setToken(incomingToken: string | null | undefined) {
    v1.defaults.headers = {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${incomingToken}`,
        'X-CSRFToken': csrftoken,
      },
    };
  }

  public async getClients() {
    return await v1.get(`/user/clients`);
  }

  public async createUser(params: IUserParams) {
    return await v1.post('/user/register', params);
  }

  public async processVideo(params: IProcessVideoParams) {
    const { token, userId, video } = params;
    const file = new FormData();
    file.append('video', video);
    if (userId) {
      return await v1.post(`/process?token=${token}&userId=${userId}`, file, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
    }
    return await v1.post(`/process?token=${token}`, file, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  public async authUser(params: IUserCredParams) {
    return await v1.post('/user/login', params);
  }

  public async forgotPassword(params: IForgotPasswordParams) {
    return await v1.post('/user/forgot', params);
  }

  public async resetPassword(params: IResetPasswordParams) {
    return await v1.post('/user/reset', params);
  }

  public async getUser(token: string) {
    return await v1.post(`/getUserIdStatus/${token}`);
  }

  public async verifyUser(params: IVerifyUserParams) {
    return await v1.post(`/user/verify`, params);
  }

  // DEPRECATED
  /**
   * @deprecated This, along with a few other functions have
   * been replaced with the /process endpoint
   *
   * @param token
   * @param userId
   * @returns Promise
   */
  public async getPresignedUploadLink(token: string, userId?: string) {
    return await v1.get(`/getUrl?token=${token}`);
  }

  /**
   * @deprecated This, along with a few other functions have
   * been replaced with the /process endpoint
   *
   * @param videoId
   * @param token
   * @returns Promise
   */
  public async getVideoStatus(videoId: string, token: string) {
    return await v1.get(`/getStatus/${videoId}?token=${token}`);
  }

  /**
   * @deprecated This, along with a few other functions have
   * been replaced with the /process endpoint
   *
   * @param url
   * @param file
   * @returns Promise
   */
  public async uploadVideo(url: string, file: Blob | null) {
    return await Axios.put(url, file);
  }
}

export const Api = new API();
