import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import './button.scss';

declare type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'outline-primary'
  | 'outline-secondary';

declare type ButtonType = 'button' | 'submit' | 'reset' | undefined;

interface IButton {
  label: string;
  icon?: any;
  variant?: ButtonVariant;
  type?: ButtonType;
  to?: string;
  disabled?: boolean;
  isLoading?: boolean;
  style?: any;
  className?: string;
  onClick?: (event: any) => void;
}

export const Button: React.FC<IButton> = ({
  label = 'Click Me!',
  icon,
  variant = 'primary',
  type,
  to,
  disabled,
  isLoading = false,
  style,
  className,
  onClick,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (to) history.push(to);
  };

  return (
    <button
      className={`button${' --' + variant}${disabled ? ' --disabled' : ''}${
        ' ' + className
      }`}
      style={style}
      onClick={onClick || handleClick}
      type={type}
      disabled={disabled}
    >
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <>
          <div style={{ marginRight: 16 }}>{icon}</div>
          {label}
        </>
      )}
    </button>
  );
};
