/* eslint-disable no-useless-escape */
import { Form, FormControl } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useEffect, useState } from 'react';

import { Api } from '../../utils/Api';
import { loginUser } from '../../store/actions/user';
import { useDispatch } from 'react-redux';

import { Verify } from '../../components/Verify';

import './signup.scss';
import { useHistory } from 'react-router-dom';

export const SignupScreen: React.FC = () => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    phone: '',
    client: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [requiresVerification, setRequiresVerification] = useState(false);
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);
  const [clients, setClients] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getClients();
  }, []);

  const schema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
    email: yup
      .string()
      .email(`This doesn't look like a valid email. Try again?`)
      .required('Email is a required field'),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+{}|[\]:\\";'<=\-+>?,./`~]{6,}$/,
        'Your password needs to be at least 6 characters long',
      )
      .required('Password is a required field'),
    phone: yup
      .string()
      .matches(
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
        'Not a valid phone number',
      )
      .required('Phone is a required field'),
    client: yup.string().oneOf(clients).optional(),
  });

  const getClients = async () => {
    const { data } = await Api.getClients();
    setClients(data.clients);
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    const createResp = await Api.createUser(values);
    setRequiresVerification(!createResp.data.error);
    setError(createResp.data.error);

    const { email, password, client } = values;
    const { data } = await Api.authUser({
      username: email,
      password,
      client,
    });
    await dispatch(loginUser({ token: data.token }));

    setValues(values);

    setIsLoading(false);
  };

  const handleValidCode = () => {
    history.push('/dashboard');
  };

  const handleDropdownClick = () => setIsDropdownShowing(!isDropdownShowing);

  const renderDropdownItem = (
    client: string,
    setFieldValue: (field: string, value: string) => void,
  ) => {
    return (
      <li
        className={
          values.client === client
            ? 'dropdown-menu-item --selected'
            : 'dropdown-menu-item'
        }
        onClick={() => setFieldValue('client', client)}
        id={client}
      >
        {client}
      </li>
    );
  };

  return (
    <Page includeHeader includeBackButton title="Sign up">
      {!requiresVerification ? (
        <div className="signup">
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={values}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({
              handleSubmit,
              setFieldValue,
              validateField,
              values,
              errors,
            }) => {
              const handleBlur = (fieldName: string) => (e: any) => {
                if (!e.target.value) {
                  return;
                }

                validateField(fieldName);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <h1>Sign Up</h1>
                  {error && (
                    <div className="statusMessage --error">{error}</div>
                  )}
                  <InputGroup className="mt-6 form">
                    <InputGroup.Text className="form-item --text">
                      Name
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Enter name"
                      className="form-item --input"
                      value={values.name}
                      name="name"
                      isInvalid={!!errors.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      onBlur={handleBlur('name')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.name}
                    </FormControl.Feedback>
                  </InputGroup>
                  <InputGroup className="mt-3 form">
                    <InputGroup.Text className="form-item --text">
                      Email
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Enter email address"
                      className="form-item --input"
                      type="email"
                      value={values.email}
                      name="email"
                      isInvalid={!!errors.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      onBlur={handleBlur('email')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.email}
                    </FormControl.Feedback>
                  </InputGroup>
                  <InputGroup className="mt-3 form">
                    <InputGroup.Text className="form-item --text">
                      Password
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Create a strong password"
                      className="form-item --input"
                      type="password"
                      value={values.password}
                      name="password"
                      isInvalid={!!errors.password}
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                      onBlur={handleBlur('password')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.password}
                    </FormControl.Feedback>
                  </InputGroup>
                  <InputGroup className="mt-3 form">
                    <InputGroup.Text className="form-item --text">
                      Phone
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Enter phone number"
                      className="form-item --input"
                      type="tel"
                      value={values.phone}
                      name="phone"
                      isInvalid={!!errors.phone}
                      onChange={(e) => setFieldValue('phone', e.target.value)}
                      onBlur={handleBlur('phone')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.phone}
                    </FormControl.Feedback>
                  </InputGroup>
                  <InputGroup className="mt-3 mb-3 form">
                    <InputGroup.Text className="form-item --text">
                      Client
                    </InputGroup.Text>
                    <div className="dropdown" onClick={handleDropdownClick}>
                      <button
                        className="dropdown-toggle form-item --dropdown"
                        type="button"
                      >
                        {values.client || 'Enter Client'}
                        <span className="caret"></span>
                      </button>
                      <ul
                        className={
                          isDropdownShowing
                            ? 'dropdown-menu show'
                            : 'dropdown-menu'
                        }
                      >
                        {clients.map((client: string) =>
                          renderDropdownItem(client, setFieldValue),
                        )}
                      </ul>
                    </div>
                    <FormControl.Feedback type="invalid">
                      {errors.client}
                    </FormControl.Feedback>
                  </InputGroup>
                  <Button
                    label="Sign Up"
                    type="submit"
                    style={{ marginBottom: 16 }}
                    isLoading={isLoading}
                  />
                </Form>
              );
            }}
          </Formik>
          <div>
            ALREADY HAVE AN ACCOUNT?
            <Button label="Login" variant="outline-secondary" to="/login" />
          </div>
        </div>
      ) : (
        <Verify email={values.email} onValidCode={handleValidCode} />
      )}
    </Page>
  );
};
