import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as screens from './screens';
import { IState } from './store/reducers';
import { loadClients } from './store/actions/clients';
import { Api } from './utils/Api';

const Config = () => {
  const { token } = useSelector((state: IState) => state.user);

  React.useEffect(() => {
    if (token) {
      init();
    }
  }, [token]);

  const init = async () => {};

  return null;
};

const ProtectedRoute = ({ condition, component, ...props }: any) => {
  if (!condition) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} component={component} />;
};

const NotFound = () => {
  return <Redirect to="/" />;
};

export const Router: React.FC = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state: IState) => state.user);
  const isAuthenticated = !!token;

  React.useEffect(() => {
    Api.getClients().then(({ data }) =>
      dispatch(loadClients(data.clients.clients)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Config />
      <Switch>
        <Route exact path="/" component={screens.LandingScreen} />
        <Route exact path="/login" component={screens.LoginScreen} />
        <Route exact path="/signup" component={screens.SignupScreen} />
        <Route
          exact
          path="/forgot_password"
          component={screens.ForgotPasswordScreen}
        />
        <Route
          exact
          path="/password_reset"
          component={screens.ResetPasswordScreen}
        />
        <ProtectedRoute
          exact
          path="/dashboard"
          component={screens.DashboardScreen}
          condition={isAuthenticated}
        />
        <ProtectedRoute
          exact
          path="/results"
          component={screens.ResultsScreen}
          condition={isAuthenticated}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
