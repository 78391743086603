import * as types from '../types';

const initialClientsState = {
  clients: [],
};

export const clients = (state = initialClientsState, action: any) => {
  switch (action.type) {
    case types.LOAD_CLIENTS:
      return {
        ...state,
        clients: state.clients,
      };

    default:
      return state;
  }
};
