import { Formik } from 'formik';
import { useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { Api } from '../../utils/Api';

import './forgotPassword.scss';

export const ForgotPasswordScreen: React.FC = () => {
  const [values, setValues] = useState({
    email: '',
  });
  const [error, setError] = useState(null);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`This doesn't look like a valid email. Try again?`)
      .required(),
  });

  const handleSubmit = async (values: any) => {
    setValues(values);

    setIsLoading(true);

    Api.forgotPassword({ email: values.email })
      .then(({ data }) => {
        if (data.error) {
          setError(data.error);
        } else {
          setIsEmailSent(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Page includeHeader includeBackButton title="Forgot password">
      <div className="forgotPassword">
        {isEmailSent ? (
          <div className="statusMessage --success">
            Email Sent! Please check your inbox for a password reset link.
          </div>
        ) : (
          <>
            <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={values}
              validateOnBlur
              validateOnChange={false}
            >
              {({
                handleSubmit,
                setFieldValue,
                validateField,
                values,
                errors,
              }) => {
                const isSubmitDisabled = !!errors.email || !values.email;
                return (
                  <Form onSubmit={handleSubmit}>
                    <h1>Forgot password?</h1>
                    {error && (
                      <div className="statusMessage --error">{error}</div>
                    )}
                    <InputGroup className="mt-4 form">
                      <InputGroup.Text className="form-item --text">
                        Email
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Enter email address"
                        className="form-item --input"
                        type="email"
                        value={values.email}
                        name="email"
                        isInvalid={!!errors.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        onBlur={() => validateField('email')}
                      />
                      <FormControl.Feedback type="invalid">
                        {errors.email}
                      </FormControl.Feedback>
                    </InputGroup>
                    <Button
                      label="reset password"
                      type="submit"
                      disabled={isSubmitDisabled}
                      className="mt-4"
                      isLoading={isLoading}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
        <Button label="back to login" variant="outline-primary" to="/login" />
      </div>
    </Page>
  );
};
