import { LoadingOutlined } from '@ant-design/icons';
import * as React from 'react';

import './loading.scss';

interface ILoading {
  className?: string;
  message?: string;
}

export const Loading: React.FC<ILoading> = (props) => {
  const message = props.message || ' Processing...';
  return (
    <div className={`loading${' ' + props?.className}`}>
      <LoadingOutlined width={85} />
      {message}
    </div>
  );
};
