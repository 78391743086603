import { Formik } from 'formik';
import { useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { Api } from '../../utils/Api';
import { ClientEnum } from '../../lib/typings';

import './login.scss';
import { loginUser } from '../../store/actions/user';
import { useDispatch } from 'react-redux';
import { Verify } from '../../components/Verify';

export const LoginScreen: React.FC = () => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requiresVerification, setRequiresVerification] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(`This doesn't look like a valid email. Try again?`)
      .required('Email is a required field'),
    password: yup.string().required('Password is a required field'),
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values: any) => {
    setIsLoading(true);

    const { data } = await Api.authUser({
      username: values.email,
      password: values.password,
      client: ClientEnum.vi,
    });
    setValues(values);
    if (data.result === 'success') {
      dispatch(loginUser(data));

      history.push(`/dashboard`);
    } else if (data.error && data.token) {
      dispatch(loginUser(data));
      setRequiresVerification(true);
    } else {
      setError(data.error);
    }
    setIsLoading(false);
  };

  const handleValidCode = () => {
    history.push('/dashboard');
  };

  return (
    <Page includeHeader includeBackButton title="Log in">
      {!requiresVerification ? (
        <div className="login">
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={values}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({
              handleSubmit,
              setFieldValue,
              validateField,
              values,
              errors,
            }) => {
              const isSubmitDisabled = !!errors.email || !!errors.password;

              const handleBlur = (fieldName: string) => (e: any) => {
                if (!e.target.value) {
                  return;
                }

                validateField(fieldName);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <h1>Let’s sign you in.</h1>
                  <h2>
                    Welcome back.
                    <br />
                    You’ve been missed!
                  </h2>
                  {error && (
                    <div className="statusMessage --error">{error}</div>
                  )}
                  <InputGroup className="mt-3 form">
                    <InputGroup.Text className="form-item --text">
                      Email
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Enter email address"
                      className="form-item --input"
                      type="email"
                      value={values.email}
                      name="email"
                      isInvalid={!!errors.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      onBlur={handleBlur('email')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.email}
                    </FormControl.Feedback>
                  </InputGroup>
                  <InputGroup className="mt-3 mb-4 form">
                    <InputGroup.Text className="form-item --text">
                      Password
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Enter Password"
                      className="form-item --input"
                      type="password"
                      value={values.password}
                      name="password"
                      isInvalid={!!errors.password}
                      onChange={(e) =>
                        setFieldValue('password', e.target.value)
                      }
                      onBlur={handleBlur('password')}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.password}
                    </FormControl.Feedback>
                  </InputGroup>
                  <a href="/forgot_password" className="forgotPasssword">
                    Forgot password?
                  </a>
                  <Button
                    label="log in"
                    type="submit"
                    variant="secondary"
                    disabled={isSubmitDisabled}
                    className="mt-4"
                    isLoading={isLoading}
                  />
                </Form>
              );
            }}
          </Formik>
          <div>
            DON’T HAVE AN ACCOUNT?
            <Button label="sign up" variant="outline-primary" to="/signup" />
          </div>
        </div>
      ) : (
        <Verify email={values.email} onValidCode={handleValidCode} />
      )}
    </Page>
  );
};
