import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Drop2Outlined } from '../../assets/images/Drop2Outlined';
import { HeartRateOutlined } from '../../assets/images/HeartRateOutlined';
import { LungsOutlined } from '../../assets/images/LungsOutlined';
import { Button } from '../../components/Button';
import { Page } from '../../components/Page';
import { VideoStatusEnum } from '../../lib/typings';
import { logoutUser } from '../../store/actions/user';

import './results.scss';

export const ResultsScreen: React.FC = (props: any) => {
  const [results, setResults] = useState({
    temp: null,
    heartRate: null,
    breathRate: null,
    spo2: null,
    result: false,
  });
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { results, status } = props.location.state[0];
    setIsError(status === VideoStatusEnum.Failed);
    setResults(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      const params = new URLSearchParams(window.location.search);
      const videoId = params.get('videoId');

      navigator
        .share({
          title: 'Vital Intelligence Results',
          text: `Date Created: ${new Date()}`,
          url: `${process.env.PUBLIC_URL}/results?videoId=${videoId}`,
        })
        .catch((error) => console.log('Sharing failed', error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  };

  const handleDone = (event: any) => {
    event.preventDefault();

    dispatch(logoutUser());
    history.push('/');
  };

  return (
    <Page includeHeader title="Results">
      <div className="results">
        <div className="results-container">
          <div className="results-container-items">
            <>
              <h1>Your Results</h1>
              {isError && !results && (
                <div className="statusMessage --error">
                  Unable to show results
                </div>
              )}
              {/* Hear Rate */}
              <div className="results-container-items-item">
                <div className="results-container-items-item-icon">
                  <HeartRateOutlined stroke="#57575F" />
                </div>
                <div className="results-container-items-item-type">
                  Heart Rate
                </div>
                <div
                  className="results-container-items-item-value"
                  style={
                    !results?.heartRate
                      ? { color: '#00000061' }
                      : { color: '#07BD80' }
                  }
                >
                  {results?.heartRate || '--'}
                </div>
              </div>
              {/* Breathing Rate */}
              <div className="results-container-items-item">
                <div className="results-container-items-item-icon">
                  <LungsOutlined stroke="#57575F" />
                </div>
                <div className="results-container-items-item-type">
                  Breathing Rate
                </div>
                <div
                  className="results-container-items-item-value"
                  style={
                    !results?.breathRate
                      ? { color: '#00000061' }
                      : { color: '#07BD80' }
                  }
                >
                  {results?.breathRate || '--'}
                </div>
              </div>
              {/* SpO2 */}
              <div className="results-container-items-item">
                <div className="results-container-items-item-icon">
                  <Drop2Outlined stroke="#57575F" />
                </div>
                <div className="results-container-items-item-type">SpO2</div>
                <div
                  className="results-container-items-item-value"
                  style={
                    !results?.spo2
                      ? { color: '#00000061' }
                      : { color: '#07BD80' }
                  }
                >
                  {results?.spo2 ? Number(results?.spo2)?.toFixed(2) : '--'}
                </div>
              </div>
              <Button
                label="Share"
                variant="outline-secondary"
                onClick={handleShare}
              />
              <Button
                label="Done"
                onClick={handleDone}
                variant="secondary"
                style={{ marginBottom: 24 }}
              />
            </>
          </div>
        </div>
      </div>
    </Page>
  );
};
