export interface IFormItem {
  value: string;
  isValid: boolean;
  validation: RegExp;
}

export interface ISvg {
  className?: string;
  style?: any;
  stroke?: string;
  onClick?: (event: any) => void;
}

export type ResultType =
  | 'Temperature'
  | 'Heart rate'
  | 'Breathing rate'
  | 'SpO2';

export enum VideoStatusEnum {
  'Created' = 0,
  'Uploaded' = 1,
  'Enqueued' = 2,
  'Processing' = 3,
  'Done' = 4,
  'Failed' = 5,
}

export enum ClientEnum {
  'fobi' = 'Fobi',
  'vi' = 'VI',
}

export interface IUser {
  id: string;
  email: string;
  client: ClientEnum;
  phone: string;
  name: string;
}

export interface IUserParams {
  email: string;
  password: string;
  client: ClientEnum;
  phone: string;
  name: string;
}

export interface IUserCredParams {
  username: string;
  password: string;
  client: ClientEnum;
}

export interface IForgotPasswordParams {
  email: string;
}

export interface IResetPasswordParams {
  token: string;
  password: string;
}

export interface IVerifyUserParams {
  token: string;
  code: string;
}

export interface IProcessVideoParams {
  token: string;
  video: Blob;
  userId?: string;
}
