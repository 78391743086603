import * as React from 'react';
import { Page } from '../../components/Page';
import demo from '../../assets/images/demo.svg';
import dots from '../../assets/images/dots.svg';
import crystal from '../../assets/images/crystal.svg';

import './landing.scss';
import { Button } from '../../components/Button';
import { Logo } from '../../assets/images/Logo';

export const LandingScreen: React.FC = () => {
  const [isMobile, setIsMobile] = React.useState(true);

  const screenWidth = window.innerWidth;

  React.useEffect(() => {
    screenWidth <= 992 ? setIsMobile(true) : setIsMobile(false);
  }, [screenWidth]);

  return (
    <Page title="Home">
      <div className="landing">
        <div className="landing-gradient" />
        {isMobile && (
          <div className="landing-logo">
            <Logo />
          </div>
        )}
        <div className="landing-content">
          <img
            className="landing-dots"
            src={dots}
            alt="dots"
            style={{ left: 0, top: 388 }}
          />

          <div className="landing-content-demoContainer">
            <div className="landing-content-demoContainer-vitals">
              <div className="landing-content-demoContainer-vitals-stats">
                BP 120/80
              </div>
              <div className="landing-content-demoContainer-vitals-line" />
              <div className="landing-content-demoContainer-vitals-crystal">
                <img
                  src={crystal}
                  className="landing-content-demoContainer-crystal"
                  alt="crystal"
                />
              </div>
            </div>

            <img
              src={demo}
              className="landing-content-demoContainer-face"
              alt="face"
            />
          </div>
          <img
            className="landing-dots"
            src={dots}
            alt="dots"
            style={{ right: 0, top: 110 }}
          />
          <div className="landing-content-authContainer">
            {!isMobile && (
              <div className="landing-logo">
                <Logo />
              </div>
            )}
            <div>
              <h1>Vital Sign Detection</h1>
              <div className="landing-content-authContainer-buttonGroup">
                <Button to="/signup" label="Sign Up" variant="primary" />
                <Button to="/login" label="Log In" variant="secondary" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
